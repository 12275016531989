export enum DwollaCustomerStatus {
  Unverified = 'unverified',
  Retry = 'retry',
  Document = 'document',
  Verified = 'verified',
  Suspended = 'suspended',
  Deactivated = 'deactivated',
  Reactivated = 'reactivated',
  Pending = 'pending',
}
