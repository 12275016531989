import { $fetch } from 'ofetch';
import type { RuntimeConfig } from 'nuxt/schema';

export default defineNuxtPlugin(() => {
  const runtimeConfig: RuntimeConfig = useRuntimeConfig();
  if (!globalThis.$fetch) {
    globalThis.$fetch = $fetch.create({
      headers: { 'X-Snapr-Prefix': runtimeConfig.public.APP_PREFIX },
    });
  }
});
