
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "479a3a1e-bd02-4a45-9f40-ebdbb7b437aa"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/home/app/base/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
